let boldrGoogleMapsIsInitialized = false;
let boldrGoogleMapsFunctions = [];
window.boldrGoogleMapsMaps = {};

window.boldrGoogleMapsInitialized = function() {
    boldrGoogleMapsIsInitialized = true;
    for (let handler of boldrGoogleMapsFunctions)
    {
        handler();
    }
}

function boldrGoogleMapsRegisterInitFunction(func) {
    if (boldrGoogleMapsIsInitialized)
    {
        func();
    }
    else
    {
        boldrGoogleMapsFunctions.push(func);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    boldrGoogleMapsRegisterInitFunction(function() {
        // Register HTMLMapMarker class
        class HTMLMapMarker extends google.maps.OverlayView {
            constructor(args) {
                super();
                this.latlng = args.latlng;
                this.elm = args.elm;
                this.setMap(args.map);
            }

            createDiv() {
                this.div = document.createElement('div');
                this.div.style.position = 'absolute';
                this.div.style.zIndex = 35;
                if (this.elm) {
                    this.div.appendChild(this.elm);
                }
                google.maps.event.addDomListener(this.div, 'click', event => {
                    google.maps.event.trigger(this, 'click');
                });
            }

            appendDivToOverlay() {
                const panes = this.getPanes();
                panes.overlayLayer.appendChild(this.div);
            }

            positionDiv() {
                const point = this.getProjection().fromLatLngToDivPixel(this.latlng);
                const bounds = this.div.getBoundingClientRect();
                if (point) {
                    this.div.style.left = `${point.x - (bounds.width / 2)}px`;
                    this.div.style.top = `${point.y - bounds.height}px`;
                }
            }

            draw() {
                if (!this.div) {
                    this.createDiv();
                    this.appendDivToOverlay();
                }
                this.positionDiv();
            }

            remove() {
                if (this.div) {
                    this.div.parentNode.removeChild(this.div);
                    this.div = null;
                }
            }

            getPosition() {
                return this.latlng;
            }

            getBoundsLatlngs() {
                let p = this.projection;
                if (typeof p !== 'object') return [];
                const point = p.fromLatLngToDivPixel(this.latlng);
                const bounds = this.div.getBoundingClientRect();

                return [
                    // linksboven
                    p.fromDivPixelToLatLng({ x: point.x - (bounds.width / 2), y: point.y - bounds.height }),
                    // rechtsboven
                    p.fromDivPixelToLatLng({ x: point.x + (bounds.width / 2), y: point.y - bounds.height }),
                    // linksonder
                    p.fromDivPixelToLatLng({ x: point.x - (bounds.width / 2), y: point.y }),
                    // rechtsonder
                    p.fromDivPixelToLatLng({ x: point.x + (bounds.width / 2), y: point.y })
                ];
            }

            getDraggable() {
                return false;
            }
        }

        const initMaps = function() {
            let elms = document.querySelectorAll('.boldr-google-maps-map:not([data-map-initialized])');
            for (let elm of elms)
            {
                if (!elm.id)
                {
                    elm.id = 'boldr_google_maps_map_' + Math.round(Math.random(10000,99999));
                }

                elm.setAttribute('data-map-initialized', '');
                // Map options
                let mapId = elm.getAttribute('data-map-id');
                let zoom = parseFloat(elm.getAttribute('data-zoom')) || 8;
                let disableDefaultUI = elm.hasAttribute('data-disable-default-ui');
                let scrollwheel = elm.getAttribute('data-scrollwheel') != 'false';
                let fitToBounds = elm.hasAttribute('data-fit-to-bounds');
                let fitToBoundsOffset = elm.getAttribute('data-fit-to-bounds-offset') || 0;

                let styles = {};
                let stylesElem = elm.querySelector('.boldr-google-maps-styles');
                if(stylesElem){
                    styles = eval(stylesElem.innerHTML);
                }

                // Markers
                let markers = elm.querySelectorAll('.boldr-google-maps-marker');
                let nMarkers = markers.length;
                let mapMarkers = [];
                let latitudes = [];
                let longitudes = [];

                for (let marker of markers)
                {
                    // Detach from DOM
                    marker.remove();

                    let latitude = parseFloat(marker.getAttribute('data-latitude'));
                    let longitude = parseFloat(marker.getAttribute('data-longitude'));
                    if (isNaN(latitude) || isNaN(longitude))
                        continue;

                    latitudes.push(latitude);
                    longitudes.push(longitude);
                }

                // Determine center
                let center;
                if (elm.hasAttribute('data-center'))
                {
                    let centerCoordinates = elm.getAttribute('data-center').split(',').map(p => parseFloat(p.trim()));
                    center = { lat: parseFloat(centerCoordinates[0]), lng: parseFloat(centerCoordinates[1]) };
                }
                else if (nMarkers)
                {
                    let avgLatitude = latitudes.reduce((a, b) => a + b, 0) / nMarkers;
                    let avgLongitude = longitudes.reduce((a, b) => a + b, 0) / nMarkers;
                    center = { lat: parseFloat(avgLatitude), lng: parseFloat(avgLongitude) };
                }
                else
                {
                    center = { lat: 0, lng: 0 };
                }

                // Create polylines
                let polylineElements = elm.querySelectorAll('.boldr-google-maps-polyline');
                let polylineBounds = [];
                let polylinePaths = [];
                let polylineHrefs = [];
                for (let polylineElement of polylineElements)
                {
                    let path = [];
                    let points = JSON.parse(polylineElement.dataset.points);

                    for (let point of points)
                    {
                        const latlng = new google.maps.LatLng(point[0], point[1]);
                        path.push(latlng);
                        polylineBounds.push(latlng);
                    }

                    polylinePaths.push(path);
                    polylineHrefs.push(polylineElement.dataset.href);
                }

                // Create map
                let map = new google.maps.Map(elm, {
                    mapId: mapId,
                    disableDefaultUI: disableDefaultUI,
                    scrollwheel: scrollwheel,
                    center: center,
                    zoom: zoom,
                    styles: styles
                });
                window.boldrGoogleMapsMaps[elm.id] = map;

                // after bounds change, arent we too far zoomed in?
                let boundsChanged = false;
                map.addListener('idle', ()=>{
                    if(boundsChanged){
                        if(map.getZoom() > 18) map.setZoom(18);
                        boundsChanged = false;
                    }
                });

                // Add markers
                for (let markerIndex = 0; markerIndex < nMarkers; markerIndex ++)
                {
                    let mapMarker = new HTMLMapMarker({
                        latlng: new google.maps.LatLng(latitudes[markerIndex], longitudes[markerIndex]),
                        elm: markers[markerIndex],
                        map: map
                    });
                    mapMarkers.push(mapMarker);
                }

                // Add polylines
                for (let i in polylinePaths)
                {
                    const line = new google.maps.Polyline({
                        path: polylinePaths[i],
                        geodesic: true,
                        map: map,
                        strokeOpacity: 0,
                        icons: [{
                            icon: {
                                path: 'M 0,-0.5 0,0.5',
                                strokeOpacity: 1,
                                scale: 3,
                            },
                            offset: '0',
                            repeat: '8px',
                        }]
                    });

                    const polylineLength = elm.dataset.distance ? elm.dataset.distance : (Math.round(google.maps.geometry.spherical.computeLength(line.getPath()) / 10) / 100);

                    const polylineLengthElement = document.createElement('div');
                    polylineLengthElement.classList.add('boldr-google-maps-polyline-length');
                    polylineLengthElement.innerHTML = `<i class="fa-solid fa-ruler-horizontal"></i> ${polylineLength} km`;
                    elm.appendChild(polylineLengthElement);

                    const routeLink = document.createElement('a');
                    routeLink.innerHTML = '<i class="fa-solid fa-map-location-dot"></i> Open in Google Maps';
                    routeLink.classList.add('boldr-google-maps-directions-link');
                    routeLink.setAttribute('href', polylineHrefs[i]);
                    routeLink.setAttribute('target', '_blank');
                    elm.appendChild(routeLink);
                }

                // Fit bounds
                if (fitToBounds)
                {
                    let rightMostMarker = null;
                    let bottomMostMarker = null;

                    for (let marker of markers)
                    {
                        if (rightMostMarker === null)
                            rightMostMarker = marker;

                        if (bottomMostMarker === null)
                            bottomMostMarker = marker;

                        let latitude = parseFloat(marker.getAttribute('data-latitude'));
                        let longitude = parseFloat(marker.getAttribute('data-longitude'));

                        let rightMostLatitude = parseFloat(rightMostMarker.getAttribute('data-latitude'));
                        let bottomMostLongitude = parseFloat(bottomMostMarker.getAttribute('data-longitude'));

                        if (latitude > rightMostLatitude)
                            rightMostMarker = marker;
                        if (longitude < bottomMostLongitude)
                            bottomMostMarker = marker;
                    }

                    map.fitBoundsCorrectly = () => {
                        let topOffset = 0;
                        const bounds = new google.maps.LatLngBounds();
                        for (let mapMarker of mapMarkers)
                        {
                            bounds.extend(mapMarker.getPosition());
                            if(mapMarker.elm.classList.contains('opened')){
                                topOffset = 300;
                            }
                        }
                        for (let point of polylineBounds)
                        {
                            bounds.extend(point);
                        }

                        map.fitBounds(bounds, {
                            top: fitToBoundsOffset + topOffset,
                            left: fitToBoundsOffset,
                            right: fitToBoundsOffset,
                            bottom: fitToBoundsOffset
                        });

                        boundsChanged = true;
                    };

                    map.refit = () => {
                        const bounds = new google.maps.LatLngBounds();
                        for (let mapMarker of mapMarkers)
                        {
                            for (let point of mapMarker.getBoundsLatlngs())
                            {
                                bounds.extend(point);
                            }
                        }
                        for (let point of polylineBounds)
                        {
                            bounds.extend(point);
                        }

                        map.fitBounds(bounds, {
                            top: fitToBoundsOffset,
                            left: fitToBoundsOffset,
                            right: fitToBoundsOffset,
                            bottom: fitToBoundsOffset
                        });

                        boundsChanged = true;
                    };

                    window.addEventListener('resize', map.fitBoundsCorrectly);
                    setTimeout(map.fitBoundsCorrectly, 100);
                }

                let event = new Event('boldr_google_maps.map_initialized');
                event.map = map;
                document.dispatchEvent(event);
            }
        };

        initMaps();
        document.addEventListener('boldr_google_maps.map_added', initMaps);
    });

    let script = document.createElement('script');
    script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCb3QkDjfIGaKp4dSuveCkfVYzcnl2K-aw&libraries=geometry&callback=boldrGoogleMapsInitialized';
    script.async = true;
    document.head.appendChild(script);
});
