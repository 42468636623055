document.addEventListener('click', e => {
    if (!e.target.closest)
    {
        return;
    }

    let link = e.target.closest('.job-offers-filters a') || e.target.closest('.job-offers-pagination a');
    if (link)
    {
        let jobOffers = e.target.closest('.job-offers-section');
        jobOffers.classList.add('loading');

        e.preventDefault();

        fetch(link.href, {
            method: 'GET'
        }).then(r => r.text()).then(d => {
            let div = document.createElement('div');
            div.innerHTML = d;
            let newJobOffers = div.querySelector('.job-offers-section');
            newJobOffers.classList.add('loading');
            jobOffers.parentNode.replaceChild(newJobOffers, jobOffers);
            newJobOffers.classList.remove('loading');
        });
    }
})