import { Fancybox } from "@fancyapps/ui"; '@fancyapps/ui/dist/fancybox/fancybox.esm.js';

// theme color
let themeColorCurrent = [0,0,0];
let themeColorTarget = [0,0,0];

const metaThemeColor = document.head.querySelector('meta[name="theme-color"]');
const themeColor = metaThemeColor.getAttribute('content');

function animate(){
    requestAnimationFrame(animate);

    const mod = 12;
    let modR = (themeColorTarget[0] - themeColorCurrent[0]) / mod;
    let modG = (themeColorTarget[1] - themeColorCurrent[1]) / mod;
    let modB = (themeColorTarget[2] - themeColorCurrent[2]) / mod;

    themeColorCurrent[0] = Math.ceil(themeColorCurrent[0] + modR);
    themeColorCurrent[1] = Math.ceil(themeColorCurrent[1] + modG);
    themeColorCurrent[2] = Math.ceil(themeColorCurrent[2] + modB);

    metaThemeColor.setAttribute('content', `rgb(${themeColorCurrent.join(',')})`);

    updateTop();
}

function hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? [
        parseInt(result[1], 16),
        parseInt(result[2], 16),
        parseInt(result[3], 16)
    ] : null;
}

document.addEventListener('click', e => {
    // back-to-top
    if (e.target.closest && e.target.closest('.back-to-top'))
    {
        e.preventDefault();
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    // submenus
    if (e.target.closest && e.target.closest('.menu-item') && e.target.closest('.menu-item').querySelector('.menu-item-children'))
    {
        e.preventDefault();
        let submenu = document.querySelector('.mobile-sub-menu .mobile-menu-menu .menu');
        submenu.innerHTML = e.target.closest('.menu-item').querySelector('.menu-item-children').innerHTML;
        document.body.classList.add('mobile-submenu-opened');
        themeColorTarget = [0,0,0];
    }

    if (e.target.closest && e.target.closest('.header-mobile-back'))
    {
        e.preventDefault();
        document.body.classList.remove('mobile-submenu-opened');
        themeColorTarget = hexToRgb(themeColor);
    }

    if (e.target.closest && e.target.closest('.header-mobile-open'))
    {
        e.preventDefault();

        setTimeout(()=>{
            document.body.classList.add('mobile-menu-perspective');
            setTimeout(()=>{
                document.body.classList.add('mobile-menu-transition');
                setTimeout(()=>{
                    document.body.classList.add('mobile-menu-opened');
                }, 10);
            }, 10);
        }, 10);


        window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
        document.documentElement.classList.add('no-scroll');
        themeColorTarget = hexToRgb(themeColor);
    }

    if (e.target.closest && e.target.closest('.header-mobile-close'))
    {
        e.preventDefault();

        setTimeout(()=>{
            document.body.classList.remove('mobile-menu-opened');
            setTimeout(()=>{
                document.body.classList.remove('mobile-menu-transition');
                setTimeout(()=>{
                    document.body.classList.remove('mobile-menu-perspective');
                }, 10);
            }, 650);
        }, 10);

        document.documentElement.classList.remove('no-scroll');
        themeColorTarget = [0,0,0];
    }

});

const updateTop = e => {
    let top = document.querySelector('.back-to-top');
    top.classList.toggle('visible', window.scrollY > 100);
    requestAnimationFrame(() => {
        let offset = Math.max(0, -(document.querySelector('.footer-wrapper').getBoundingClientRect().top - document.documentElement.clientHeight) - 50);
        top.classList.toggle('sticky', offset == 0);
    });
};
window.addEventListener('scroll', updateTop);
window.addEventListener('resize', updateTop);

// popup
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.button.popup:not(.bcms-ve-link)').forEach(elem => {
        const urlBits = elem.getAttribute('href').split('?');
        const searchParams = new URLSearchParams(
            urlBits.length > 1 ? urlBits[1] : ''
        );
        searchParams.append('embed', '');
        const url = urlBits[0] + '?' + searchParams.toString();

        let promise = fetch(url.toString()).then( response => response.text() );
        elem.addEventListener('click', (e) => {
            e.preventDefault();
                promise.then(html => {
                    Fancybox.show([{
                        src: html,
                        type: 'html'
                    }], {
                        mainClass: 'popup',
                        dragToClose: false,
                        click: false
                    });
                    document.dispatchEvent(new Event('boldr_google_maps.map_added'));
                })
            ;
        });
    });
});

// forms
const parser = new DOMParser();
document.addEventListener('submit', e => {
    if(e.target.matches('.registration-form-section .form')){
        e.preventDefault();
        e.target.classList.add('loading');

        const urlBits = e.target.getAttribute('action').split('?');
        const searchParams = new URLSearchParams(
            urlBits.length > 1 ? urlBits[1] : ''
        );
        searchParams.append('embed', '');
        const url = urlBits[0] + '?' + searchParams.toString();

        fetch(url, {
            method: 'POST',
            body: new FormData(e.target)
        })
        .then(response => response.text())
        .then(html => {
            const htmlDoc = parser.parseFromString(html, 'text/html');
            const form = htmlDoc.querySelector('.form');
            if(form){
                e.target.innerHTML = htmlDoc.querySelector('.form').innerHTML;
            } else {
                e.target.parentNode.innerHTML = html;
            }
            e.target.classList.remove('loading');
        });
    }
});


// submenu toggles
document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.menu-item-link[href^="#"], .language-selector-language[href^="#"]').forEach((menuLink)=>{
        menuLink.addEventListener('mousedown', (e)=>{
            if(document.activeElement === menuLink){
                e.preventDefault();
                menuLink.blur();
            }
        });
    });

    for (let table of document.querySelectorAll('table'))
    {
        let div = document.createElement('div');
        div.style.overflowX = 'auto';
        table.parentNode.insertBefore(div, table.nextSibling);
        div.appendChild(table);
    }
});

document.addEventListener('click', e => {
    if (!e.target.closest)
    {
        return;
    }

    if (e.target.closest('.language-selector-current-language'))
    {
        e.preventDefault();
        return;
    }

    let menuItem = e.target.closest('.header-menu-wrapper .menu-item');
    if (menuItem)
    {
        let otherWraps = document.querySelectorAll('.menu-item-children-wrap.open');
        let childWrapper = menuItem.querySelector('.menu-item-children-wrap');
        let closedChild = false;
        for (let wrap of otherWraps)
        {
            wrap.classList.remove('open');
            if (wrap === childWrapper)
            {
                closedChild = true;
            }
        }

        if (childWrapper && !closedChild)
        {
            childWrapper.classList.add('open');
        }
        else
        {
            document.body.classList.remove('mobile-submenu-opened');
        }
    }

    else if (!e.target.closest('.menu-item-children-wrap'))
    {
        let elm = document.querySelector('.menu-item-children-wrap.open');
        if (elm)
        {
            elm.classList.remove('open');
        }
    }
});

// focus fallbacks (for safari)
document.addEventListener('DOMContentLoaded', () => {
    if (window.safari)
    {
        document.addEventListener('mousedown', (e) => {
            if(e.target.classList.contains('no-focus')) return;

            document.querySelectorAll('.focus').forEach(elm => {
                elm.classList.remove('focus');
            });
        });

        document.querySelectorAll('.header-content a').forEach(elm => {
            elm.addEventListener('mousedown', (e) => {
                setTimeout(()=>{elm.classList.add('focus'); }, 1);
            });
        });
    }

    for (let group of document.querySelectorAll('[data-return-post]'))
    {
        let id = group.dataset.returnPost;
        for(let button of group.querySelectorAll('.button-return'))
        {
            let url = new URL(button.href);
            url.searchParams.set('return', id);

            if (document.body.hasAttribute('data-students'))
            {
                url.searchParams.set('students', '');
            }

            button.href = url.toString();
        }
    }
});

animate();
