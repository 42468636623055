(function() {
    const init = function() {
        for (let layout of document.querySelectorAll('.boldr-variable-grid-layout:not([data-initialized])'))
        {
            layout.setAttribute('data-initialized', '');

            new Masonry(layout.querySelector('.boldr-variable-grid-layout-elements'), {
                percentPosition: true,
                gutter: '.boldr-variable-grid-layout-spacer',
                columnWidth: '.boldr-variable-grid-layout-sizer',
                itemSelector: '.boldr-variable-grid-layout-element'
            });
        }
    };

    document.addEventListener('DOMContentLoaded', init);
    document.addEventListener('bcms.ve.section-initialized', init);
})()
