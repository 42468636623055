import './styles/app.less';

import './scripts/layout.js';
import './scripts/trails.js';
import './scripts/beeldbank.js';
import './scripts/agenda.js';
import './scripts/job-offers.js';
import './scripts/maps.js';
import './scripts/masonry.js';
import './scripts/gridlayout.js';

import './scripts/fancybox.js';
import './styles/fancybox.css';

import '@blixem/cms-bundle/admin/vendor/fontawesome-pro/css/all.css';

function refreshSurpriseMe(surpriseMe) {
    let current = surpriseMe.querySelector('.surprise-me-company.surprise-me-current');
    let items = surpriseMe.querySelectorAll('.surprise-me-company');
    let images = surpriseMe.querySelectorAll('.surprise-me-company-image');
    let links = surpriseMe.querySelectorAll('.surprise-me-company-link');

    let index = getRandomArbitrary(0, items.length - (current ? 2 : 1));
    let i = 0;
    let currentItem = null;
    for (let item of items)
    {
        item.classList.remove('surprise-me-current');
        if (item === current)
        {
            continue;
        }

        if (index == i)
        {
            currentItem = item;
            item.classList.add('surprise-me-current');
        }

        i ++;
    }

    for (let image of images)
    {
        image.classList.toggle('surprise-me-current', currentItem.dataset.company == image.dataset.company);
    }
    for (let link of links)
    {
        link.classList.toggle('surprise-me-current', currentItem.dataset.company == link.dataset.company);
    }
}

document.addEventListener('DOMContentLoaded', () => {
    for (let surpriseMe of document.querySelectorAll('.surprise-me-section'))
    {
        refreshSurpriseMe(surpriseMe);
    }
});

document.addEventListener('click', e => {
    if (e.target.closest('.surprise-me-refresh'))
    {
        e.preventDefault();
        refreshSurpriseMe(e.target.closest('.surprise-me-section'));
    }
})

function getRandomArbitrary(min, max) {
    return Math.round(Math.random() * (max - min)) + min;
}

document.addEventListener('boldr_forms.form_submit', e => {
    let button = e.target.querySelector('.boldr-forms-submit');
    if (!button.querySelector('i'))
    {
        let icon = document.createElement('i');
        icon.className = 'fa fa-spin fa-spinner';
        button.prepend(icon);
    }
});

document.addEventListener('boldr_forms.form_submitted', e => {
    let icon = e.target.querySelector('.boldr-forms-submit i');
    icon.remove();
});