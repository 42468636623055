(function() {
    document.addEventListener('mousemove', e => {
        let containers = document.querySelectorAll('.boldr-google-maps-map');

        for (let container of containers)
        {
            container.classList.remove('hover');
        }

        let markers = document.querySelectorAll('.trails-marker-poi');
        for (let marker of markers)
        {
            if (isMouseOver(e, marker))
            {
                marker.closest('.boldr-google-maps-map').classList.add('hover');
                return;
            }
        }
        let closes = document.querySelectorAll('.trails-poi-close');
        for (let close of closes)
        {
            if (isMouseOver(e, close))
            {
                close.closest('.boldr-google-maps-map').classList.add('hover');
                return;
            }
        }
    });

    let inited = [];
    document.addEventListener('boldr_google_maps.map_initialized', e => {

        // there is only 1 marker, open it
        setTimeout(()=>{
            let markers = document.querySelectorAll('.trails-marker-poi');
            if(markers.length === 1)
            {
                markers[0].classList.add('opened');
            }
        }, 500);

        for (let marker of document.querySelectorAll('.trails-marker-poi.opened'))
        {
            let mapElm = marker.closest('.boldr-google-maps-map');
            let map = boldrGoogleMapsMaps[mapElm.id];
            map.refit();
        }

        e.map.addListener('click', event => {
            if (event.placeId) {
                event.stop();
            }
        });
    });

    let zIndex = 40;
    document.addEventListener('click', e => {
        if (!e.target.closest) return;

        let mapElm = e.target.closest('.boldr-google-maps-map');
        if (mapElm)
        {
            let map = boldrGoogleMapsMaps[mapElm.id];
            let markers = mapElm.querySelectorAll('.trails-marker-poi');

            let close = [];
            let found = false;
            let refit = false;
            for (let marker of markers)
            {
                if (!found && isMouseOver(e, marker.querySelector('.trails-marker-poi-view')))
                {
                    marker.parentNode.style.zIndex = zIndex++;
                    marker.classList.add('opened');
                    const parent = marker.closest('.boldr-google-maps-map');
                    const parentBbox = parent.getBoundingClientRect();
                    const bbox = marker.getBoundingClientRect();
                    const mapBbox = mapElm.getBoundingClientRect();
                    const center = fromPixelToLatLng(
                        map,
                        ((bbox.left + bbox.right) / 2) - parentBbox.left,
                        bbox.top - mapBbox.top + 50,
                    );
                    map.panTo(center);

                    found = true;
                } else {
                    if (marker.classList.contains('opened'))
                    {
                        refit = true;
                        marker.classList.remove('opened');
                    }
                }
            }
            if (refit && !found)
            {
                setTimeout(() => map.fitBoundsCorrectly(), 250);
            }
        }
    });

    function fromPixelToLatLng(map, x, y) {
        const projection = map.getProjection();
        const topRight = projection.fromLatLngToPoint(map.getBounds().getNorthEast());
        const bottomLeft = projection.fromLatLngToPoint(map.getBounds().getSouthWest());
        const scale = 1 << map.getZoom();
        return projection.fromPointToLatLng(new google.maps.Point(x / scale + bottomLeft.x, y / scale + topRight.y));
    };


    function isMouseOver(e, marker){
        const bounds = marker.getBoundingClientRect();
        return (
            e.clientX >= bounds.left &&
            e.clientX < bounds.right &&
            e.clientY >= bounds.top &&
            e.clientY < bounds.bottom
        );
    }
})();
