let lastScrollPosition = null;

const loadImages = (searchOrFolder, folderOrQuery, page) => {
    let foldersDiv = document.querySelector('.beeldbank-folders');
    let imagesDiv = document.querySelector('.beeldbank-images');
    let imagesGridDiv = document.querySelector('.beeldbank-images-grid');
    let back = document.querySelector('.beeldbank-back');

    imagesDiv.classList.remove('visible');
    if (searchOrFolder === false)
    {
        imagesDiv.querySelectorAll('.beeldbank-image img').forEach(image => {
            image.classList.remove('loaded');
        });
        setTimeout(() => {
            foldersDiv.classList.remove('hidden');
            imagesGridDiv.textContent = '';
            back.classList.remove('visible');
        }, 300);
        return;
    }
    else
    {
        lastScrollPosition = window.scrollY;
    }

    // Hide folder list
    foldersDiv.classList.add('loading');

    let downloadButton = document.querySelector('.beeldbank-download-folder');
    downloadButton.classList.remove('visible');

    // Fetch images from /beeldbank/folder/{id}?page={page}
    // Fetch images from /beeldbank/search?q={query}&page={page}
    fetch('/beeldbank/' + searchOrFolder + (searchOrFolder === 'search' ? '?q=' + folderOrQuery + '&' : '/' + folderOrQuery + '?') + 'page=' + page)
        .then(r => r.json())
        .then(d => {
            // Hide folder list
            foldersDiv.classList.remove('loading');
            foldersDiv.classList.add('hidden');

            if (d.success)
            {
                back.classList.add('visible');

                if (searchOrFolder == 'folder' && d.isOwn)
                {
                   downloadButton.classList.add('visible');
                   downloadButton.href = '/beeldbank/download-folder/' + folderOrQuery;
                }

                // Create image grid div
                imagesGridDiv.textContent = '';

                // Add <a><img> for all images
                for (let image of d.images)
                {
                    let imageA = document.createElement('a');
                    imageA.className = 'beeldbank-image';

                    imageA.dataset.previewUrl = image.largePreviewUrl;
                    imageA.dataset.displayName = image.displayName;
                    imageA.dataset.description = image.description;
                    imageA.dataset.downloadUrl = image.downloadUrl;
                    imageA.dataset.size = image.size;

                    let img = document.createElement('img');
                    img.addEventListener('load', ()=>{
                        img.classList.add('loaded');
                    });
                    img.src = image.smallPreviewUrl;
                    img.alt = image.displayName;

                    imageA.appendChild(img);

                    imagesGridDiv.appendChild(imageA);
                }

                // @TODO Layout elements with Masonry

                // Make image grid visible
                // @TODO nice animation?

                imagesDiv.classList.add('visible');
                imagesDiv.classList.remove('loading');
            }
            else
            {
                throw 'error';
            }
        }).catch(e => {
            console.error(e);
            alert('Er is iets fout gegaan bij het laden van de beeldbank. Probeer het nogmaals.');
            imagesDiv.classList.remove('loading');
            foldersDiv.classList.remove('hidden');
            foldersDiv.classList.remove('loading');
        });
}

let currentImage = null;
const openImageWindow = (image) => {
    currentImage = image;

    const img = document.querySelector('.beeldbank-overlay-image img');

    document.querySelector('.beeldbank-overlay-name').textContent = currentImage.dataset.displayName;
    document.querySelector('.beeldbank-overlay-description').textContent = currentImage.dataset.description;
    document.querySelector('.beeldbank-overlay-download-button a').href = currentImage.dataset.downloadUrl;
    document.querySelector('.beeldbank-overlay-size').textContent = currentImage.dataset.size;
    
    document.querySelector('.beeldbank-images').classList.add('loading');
    img.addEventListener('load', () => {
        document.querySelector('.beeldbank-images').classList.remove('loading');
        document.querySelector('.beeldbank-overlay-container').classList.add('visible');
        document.querySelector('.beeldbank-overlay').classList.add('visible');
    });
    img.src = '';
    img.src = currentImage.dataset.previewUrl;
};

document.addEventListener('click', e => {
    if (!e.target.closest)
    {
        return;
    }

    // When cliking on a folder, fetch the images and show them
    let folder = e.target.closest('.beeldbank-folder');
    if (folder)
    {
        e.preventDefault();
        loadImages('folder', folder.dataset.folderId, 1);
        return;
    }

    // When closing the overlay
    let close = e.target.closest('.beeldbank-overlay-close') || e.target.matches('.beeldbank-overlay-container');
    if (close)
    {
        e.preventDefault();
        document.querySelector('.beeldbank-overlay-container').classList.remove('visible');
        return;
    }

    let openLogin = e.target.closest('.beeldbank-open-login');
    if (openLogin)
    {
        e.preventDefault();
        document.querySelector('.beeldbank-overlay').classList.remove('visible');
        document.querySelector('.beeldbank-overlay-container').classList.add('visible');
        document.querySelector('.beeldbank-login-overlay').classList.add('visible');
        return;
    }

    // When clicking on an image in the folder/search results
    let image = e.target.closest('.beeldbank-image');
    if (image)
    {
        e.preventDefault();
        document.querySelector('.beeldbank-login-overlay').classList.remove('visible');
        openImageWindow(image);
        return;
    }


    let back = e.target.closest('.beeldbank-back');
    if (back)
    {
        e.preventDefault();
        document.querySelector('.beeldbank-search-input').value = '';
        window.scrollTo({ top: lastScrollPosition, left: 0, behavior: 'smooth' });
        loadImages(false);
        return;
    }
});

document.addEventListener('submit', e => {
    if (!e.target.closest)
    {
        return;
    }

    let form = e.target.closest('.beeldbank-overlay-login');
    if (form)
    {
        e.stopPropagation();
        e.preventDefault();

        // @TODO loading indicator

        fetch(form.action, {
            method: 'POST',
            body: new FormData(form),
            headers: {
                'Accept': 'application/json'
            }
        })
            .then(r => r.json())
            .then(d => {
                if (d.success)
                {
                    document.querySelector('.beeldbank-overlay .beeldbank-overlay-login').classList.add('hidden');
                    document.querySelector('.beeldbank-overlay-download-button').classList.remove('hidden');
                    document.querySelector('.beeldbank-user-name').textContent = form.querySelector('#login-username').value;
                    document.querySelector('.beeldbank-login').classList.add('hidden');
                    document.querySelector('.beeldbank-user').classList.remove('hidden');

                    let overlayImage = document.querySelector('.beeldbank-overlay-image img');
                    overlayImage.src = overlayImage.src + '?authorized=yes';

                    for (let image of document.querySelectorAll('.beeldbank-image img, .beeldbank-folder-preview'))
                    {
                        image.src = image.src + '?authorized=yes';
                    }

                    for (let a of document.querySelectorAll('.beeldbank-image'))
                    {
                        a.dataset.previewUrl = a.dataset.previewUrl + '?authorized=yes';
                    }

                    let loginOverlay = document.querySelector('.beeldbank-login-overlay');
                    if (loginOverlay.classList.contains('visible'))
                    {
                        // Close overlay
                        loginOverlay.classList.remove('visible');

                        // Close overlay container
                        loginOverlay.parentNode.classList.remove('visible');
                    }

                    fetch('/beeldbank/user-folders', {
                        method: 'GET',
                        headers: {
                            'Accept': 'application/json'
                        }
                    }).then(r => r.json()).then(d => {
                        if (d.success) {
                            let folders = document.querySelector('.beeldbank-folders');
                            for (let folder of d.folders)
                            {
                                let a = document.createElement('a');
                                a.href = '#';
                                a.dataset.folderId = folder.id;
                                a.className = 'beeldbank-folder';

                                a.innerHTML = `
                                    <img class="beeldbank-folder-preview" src="${folder.previewUrl}" alt="${folder.name}">
                                    <span class="beeldbank-folder-name">${folder.name}</span>
                                `;

                                folders.prepend(a);
                            }
                        }
                    });
                }
                else
                {
                    alert(d.message_translated);
                }
            });
    }
})

let searchTimeout = null;
document.addEventListener('keyup', e => {
    if (!e.target.closest)
    {
        return;
    }

    let searchInput = e.target.closest('.beeldbank-search-input');
    if (searchInput)
    {
        // debounce
        if (searchTimeout)
        {
            clearTimeout(searchTimeout);
        }
        searchTimeout = setTimeout(() => {
            if (searchInput.value === '')
            {
                loadImages(false);
            }
            else
            {
                loadImages('search', searchInput.value, 1);
            }
        }, 500);
    }
})
